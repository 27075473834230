




import { Vue, Component } from 'vue-property-decorator';
import StoreFrontBannerUpsert from '@/components/StoreFront/Banner/Upsert.vue';

@Component({
  components: {
    StoreFrontBannerUpsert
  }
})
export default class StoreFrontContentBannerCreate extends Vue {
}
